// src/Footer.js

import React from 'react';
import { Box, Typography, Link } from '@mui/material';

function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: '#f0f0f0', // Gray background
        height: '50px',             // Approximately 50px in height
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 'auto',          // Pushes footer to the bottom
      }}
    >
      <Typography variant="body2" color="textSecondary">
        © {new Date().getFullYear()}{' '}
        <Link href="https://commercy.io" target="_blank" color="inherit" underline="none">
          Commercy
        </Link>
      </Typography>
    </Box>
  );
}

export default Footer;