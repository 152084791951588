// src/App.js

import React, { useState, useEffect } from 'react';
import semver from 'semver';
import {
  Container,
  TextField,
  Typography,
  InputAdornment,
  Box,
} from '@mui/material';
import { CheckCircle, Error } from '@mui/icons-material';
import Footer from './Footer';
import DonateButton from './DonateButton';
import { Analytics } from "@vercel/analytics/react"

function App() {
  const [pattern, setPattern] = useState('');
  const [version, setVersion] = useState('');
  const [patternValid, setPatternValid] = useState(null);
  const [versionValid, setVersionValid] = useState(null);
  const [isValid, setIsValid] = useState(null);

  useEffect(() => {
    // Validate pattern
    if (pattern) {
      setPatternValid(semver.validRange(pattern) !== null);
    } else {
      setPatternValid(null);
    }

    // Validate version
    if (version) {
      setVersionValid(semver.valid(version) !== null);
    } else {
      setVersionValid(null);
    }

    // Check if version satisfies pattern
    if (patternValid && versionValid) {
      const result = semver.satisfies(version, pattern);
      setIsValid(result);
    } else {
      setIsValid(null);
    }
  }, [pattern, version, patternValid, versionValid]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      minHeight="100vh"
    >
      {/* Main content */}
      <Container maxWidth="sm" sx={{ marginTop: '50px', flexGrow: 1 }}>
        <Typography variant="h4" align="center" gutterBottom>
          SemVer Checker
        </Typography>
        <TextField
          label="Version Pattern"
          variant="outlined"
          fullWidth
          margin="normal"
          value={pattern}
          onChange={(e) => setPattern(e.target.value)}
          placeholder="e.g., ^2.3.0"
          error={patternValid === false}
          InputProps={{
            endAdornment: patternValid !== null && (
              <InputAdornment position="end">
                {patternValid ? (
                  <CheckCircle style={{ color: 'green' }} />
                ) : (
                  <Error style={{ color: 'red' }} />
                )}
              </InputAdornment>
            ),
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor:
                  patternValid === false
                    ? 'red'
                    : patternValid === true
                    ? 'green'
                    : undefined,
              },
              '&:hover fieldset': {
                borderColor:
                  patternValid === false
                    ? 'red'
                    : patternValid === true
                    ? 'green'
                    : undefined,
              },
              '&.Mui-focused fieldset': {
                borderColor:
                  patternValid === false
                    ? 'red'
                    : patternValid === true
                    ? 'green'
                    : 'primary.main',
              },
            },
          }}
        />
        <TextField
          label="Version"
          variant="outlined"
          fullWidth
          margin="normal"
          value={version}
          onChange={(e) => setVersion(e.target.value)}
          placeholder="e.g., 2.5.0"
          error={versionValid === false}
          InputProps={{
            endAdornment: versionValid !== null && (
              <InputAdornment position="end">
                {versionValid ? (
                  <CheckCircle style={{ color: 'green' }} />
                ) : (
                  <Error style={{ color: 'red' }} />
                )}
              </InputAdornment>
            ),
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor:
                  versionValid === false
                    ? 'red'
                    : versionValid === true
                    ? 'green'
                    : undefined,
              },
              '&:hover fieldset': {
                borderColor:
                  versionValid === false
                    ? 'red'
                    : versionValid === true
                    ? 'green'
                    : undefined,
              },
              '&.Mui-focused fieldset': {
                borderColor:
                  versionValid === false
                    ? 'red'
                    : versionValid === true
                    ? 'green'
                    : 'primary.main',
              },
            },
          }}
        />
        {isValid !== null && (
          <Typography
            variant="h6"
            align="center"
            sx={{ color: isValid ? 'green' : 'red', marginTop: '20px' }}
          >
            {isValid
              ? 'The version matches the pattern!'
              : 'The version does not match the pattern.'}
          </Typography>
        )}
      </Container>

      {/* Footer */}
      <Footer />

      {/* Donate Button */}
      <DonateButton />
      <Analytics/>
    </Box>
  );
}

export default App;