// src/DonateButton.js

import React from 'react';
import { Box, Fab } from '@mui/material';
import { AttachMoney } from '@mui/icons-material';

function DonateButton() {
  const donateLink = process.env.REACT_APP_DONATE_LINK;

  return (
    <Box
      sx={{
        position: 'fixed',
        top: '50%',
        right: '-30px', // Adjust this value if needed
        transform: 'translateY(-50%)',
        zIndex: 1000,
      }}
    >
      <a href={donateLink} target="_blank" rel="noopener noreferrer">
        <Fab
          variant="extended"
          color="secondary"
          sx={{
            transform: 'rotate(-90deg)',
            transformOrigin: 'center center',
            width: '140px', // Adjust width to fit content
            height: '48px', // Default height for Fab
            justifyContent: 'center',
          }}
        >
          <AttachMoney sx={{ mr: 1 }} />
          Donate
        </Fab>
      </a>
    </Box>
  );
}

export default DonateButton;